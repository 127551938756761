import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import throttle from 'lodash.throttle'
import { motion, Transition, useAnimation, Variants } from 'framer-motion'

import { Col, Container, Layout } from '../../components/Layout'
import { CardTitle } from '../../components/CardTitle'
import {
  BodyText1,
  Heading2,
  Heading4,
  Heading5,
  Heading6,
} from '../../components/Heading'
import {
  EyeIcon,
  HeartIcon,
  LightIcon,
  NewsIcon,
  PackageIcon,
  PeopleIcon,
  TargetIcon,
  SoftwareIcon,
  InfraIcon,
  ExploreIcon,
} from '../../components/Icon'
import { Section } from '../../components/Section'

import './index.scss'
import { useIsMd } from '../../utils/media'
import { Slider, SliderMethods } from '../../components/Slider'
interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  subtitle?: string
  icon: React.ReactNode
  content: Paragraph[]
  className?: string
  animated?: boolean
}

const Card: React.FC<CardProps> = ({
  className,
  content,
  icon,
  title,
  subtitle,
  animated = false,
  style,
}) => {
  const isMd = useIsMd()
  const [y, setY] = useState(0)
  const transition: Transition = {
    duration: 0.4,
    ease: 'easeInOut',
  }

  const ref = useRef<HTMLDivElement | null>(null)
  const controls = useAnimation()

  const contentVariants: Variants = {
    animate: {
      y,
      transition,
    },
    hover: {
      y: 0,
      transition,
    },
  }

  const textVariants: Variants = {
    animate: {
      opacity: animated && isMd ? 0 : 1,
      transition,
    },
    hover: {
      opacity: 1,
      transition,
    },
  }

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        // const parentRect = ref.current.parentElement?.getBoundingClientRect()
        const rect = ref.current.getBoundingClientRect()
        setY(animated && isMd ? rect.height + 48 : 0)
        setTimeout(() => {
          controls.start('animate')
        }, 0)
      }
    }, 0)
  }, [isMd, animated])

  return (
    <motion.div
      className={`card ${className || ''}`}
      animate={controls}
      initial={false}
      onHoverEnd={() => controls.start('animate')}
      whileHover="hover"
      transition={transition}
      style={style}>
      {icon}
      <motion.div variants={contentVariants} className="content">
        <Heading5>{title}</Heading5>
        {!!subtitle && <Heading6 black>{subtitle}</Heading6>}
        <motion.div variants={textVariants} ref={ref}>
          {content.map((ct, index) => (
            <BodyText1 key={ct.id.toString() + index.toString()}>
              {ct.description}
            </BodyText1>
          ))}
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

interface AboutPageData {
  title: string
  head: SectionCard
  about: SectionCard
  value: SectionCard
  field: SectionCard
  set: SectionCard
  brain: SectionCard
  tech: SectionCard
  passion: SectionCard
  keen: SectionCard
  profession: SectionCard
  collaboration: SectionCard
}

export const AboutPage: React.FC<
  PageData<{ aboutPage: APIResponse<AboutPageData> }>
> = ({ pageContext }) => {
  const {
    serverData: { aboutPage },
  } = pageContext
  const {
    data: { attributes },
  } = aboutPage

  const fieldIcons = {
    set: <SoftwareIcon />,
    brain: <InfraIcon />,
    tech: <ExploreIcon />,
  }

  const fields: CardProps[] = Object.keys(fieldIcons).map((key) => {
    const item = attributes[key] as SectionCard
    return {
      title: item.title,
      content: item.words || [],
      icon: fieldIcons[key],
      subtitle: item.subtitle || '',
    }
  })

  const valueIcons = {
    passion: <HeartIcon />,
    keen: <LightIcon />,
    profession: <PackageIcon />,
    collaboration: <PeopleIcon />,
  }

  const values: CardProps[] = Object.keys(valueIcons).map((key) => {
    const item = attributes[key] as SectionCard
    return {
      className: key,
      title: item.title,
      subtitle: item.subtitle || '',
      content: item.words || [],
      icon: valueIcons[key],
    }
  })

  const isMd = useIsMd()
  const [fieldWidth, setFieldWidth] = useState<string | number>('100%')
  const fieldSliderRef = useRef<SliderMethods>(null)
  const fieldsRef = useRef<HTMLDivElement | null>(null)

  const [valueWidth, setValueWidth] = useState<string | number>('100%')
  const valueSliderRef = useRef<SliderMethods>(null)
  const valuesRef = useRef<HTMLDivElement | null>(null)

  const calcFields = () => {
    if (fieldsRef.current) {
      const rect = fieldsRef.current.getBoundingClientRect()
      setFieldWidth(isMd ? (rect.width - 24 * 2) / 3 : rect.width)
    }
  }

  const calcValues = () => {
    if (valuesRef.current) {
      const rect = valuesRef.current.getBoundingClientRect()
      setValueWidth(isMd ? (rect.width - 24) / 2 : rect.width)
    }
  }

  useEffect(() => {
    calcFields()
    calcValues()
  }, [isMd])

  useEffect(() => {
    fieldSliderRef.current?.reCalc()
  }, [fieldWidth])

  useEffect(() => {
    valueSliderRef.current?.reCalc()
  }, [valueWidth])

  const calc = throttle(() => {
    calcFields()
    calcValues()
  }, 100)

  useEffect(() => {
    window.addEventListener('resize', calc, true)
    return () => {
      window.removeEventListener('resize', calc, true)
    }
  }, [calc])

  return (
    <Layout className="about-page">
      <Helmet>
        <title>{attributes.title}</title>
      </Helmet>
      <Container className="head">
        <Heading2>{attributes.head.title}</Heading2>
        <BodyText1>{attributes.head.description}</BodyText1>
      </Container>
      <Container className="us">
        <Col md={6} className="section">
          <CardTitle>
            <NewsIcon />
            <Heading4>{attributes.about.title}</Heading4>
          </CardTitle>
          <Heading5 as="p" className="content">
            {attributes.about.description}
          </Heading5>
        </Col>
        <Col md={6}>
          <StaticImage
            src="./images/office.jpg"
            alt="card"
            className="section-img"></StaticImage>
        </Col>
      </Container>
      <Container>
        <Section
          className="invest"
          title={attributes.field.title}
          icon={<EyeIcon />}
          content={attributes.field.description}
        />
      </Container>
      <Container className="fields">
        <div ref={fieldsRef}>
          <Slider hideNums={true} container={fieldsRef} ref={fieldSliderRef}>
            {fields.map((field) => {
              return (
                <Card
                  {...field}
                  key={field.title}
                  style={{ width: fieldWidth, height: '100%' }}></Card>
              )
            })}
          </Slider>
        </div>
      </Container>
      <Container>
        <Section
          className="value"
          title={attributes.value.title}
          icon={<TargetIcon />}
          content={attributes.value.description}
        />
      </Container>
      <Container className="values">
        <div ref={valuesRef}>
          <Slider
            hideNums={true}
            container={valuesRef}
            ref={valueSliderRef}
            drag={!isMd}>
            {values.map((value) => {
              return (
                <Card
                  {...value}
                  key={value.title}
                  className={value.className}
                  animated
                  style={{ width: valueWidth }}></Card>
              )
            })}
          </Slider>
        </div>
      </Container>
    </Layout>
  )
}

export default AboutPage
