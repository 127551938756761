import React from 'react'
import { motion } from 'framer-motion'
import { CardTitle } from '../CardTitle'
import { Heading4, Heading5 } from '../Heading'
import { useCardHover } from '../../animation'

import './index.scss'

interface SectionProps {
  title: string
  icon: React.ReactNode
  content: string
  className?: string
}

export const Section: React.FC<SectionProps> = ({
  className,
  title,
  icon,
  content,
}) => {
  const { variants, transition } = useCardHover()
  return (
    <motion.div
      className={`section ${className || ''}`}
      initial="initial"
      whileHover="hover"
      variants={variants}
      transition={transition}>
      <CardTitle>
        {icon}
        <Heading4>{title}</Heading4>
      </CardTitle>
      <div className="content">
        <Heading5 as="p">{content}</Heading5>
      </div>
    </motion.div>
  )
}
